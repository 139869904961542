/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActorEventType {
  AGENT_USER_CLIENT_RELATIONSHIP_NOTE_CREATE = "AGENT_USER_CLIENT_RELATIONSHIP_NOTE_CREATE",
  AGENT_USER_CLIENT_RELATIONSHIP_NOTE_DELETE = "AGENT_USER_CLIENT_RELATIONSHIP_NOTE_DELETE",
  AGENT_USER_CLIENT_RELATIONSHIP_NOTE_EDIT = "AGENT_USER_CLIENT_RELATIONSHIP_NOTE_EDIT",
  AGENT_USER_CLIENT_RELATIONSHIP_NOTE_VIEW = "AGENT_USER_CLIENT_RELATIONSHIP_NOTE_VIEW",
  AGENT_USER_CLIENT_RELATIONSHIP_VIEW = "AGENT_USER_CLIENT_RELATIONSHIP_VIEW",
  AGENT_USER_PROFILE_CONTACT_EMAIL = "AGENT_USER_PROFILE_CONTACT_EMAIL",
  AGENT_USER_PROFILE_CONTACT_PHONE = "AGENT_USER_PROFILE_CONTACT_PHONE",
  AGENT_USER_PROFILE_CONTACT_SMS = "AGENT_USER_PROFILE_CONTACT_SMS",
  AGENT_USER_PROFILE_SHARE = "AGENT_USER_PROFILE_SHARE",
  AGENT_USER_PROFILE_VIEW = "AGENT_USER_PROFILE_VIEW",
  APP_OUTBOUND_LINK_OPEN = "APP_OUTBOUND_LINK_OPEN",
  APP_RATE = "APP_RATE",
  APP_SHARE = "APP_SHARE",
  CLIENT_USER_PROFILE_CONTACT_EMAIL = "CLIENT_USER_PROFILE_CONTACT_EMAIL",
  CLIENT_USER_PROFILE_CONTACT_PHONE = "CLIENT_USER_PROFILE_CONTACT_PHONE",
  CLIENT_USER_PROFILE_CONTACT_SMS = "CLIENT_USER_PROFILE_CONTACT_SMS",
  CLIENT_USER_PROFILE_VIEW = "CLIENT_USER_PROFILE_VIEW",
  DISPLAY_AGENT_PROFILE_CONTACT_EMAIL = "DISPLAY_AGENT_PROFILE_CONTACT_EMAIL",
  DISPLAY_AGENT_PROFILE_CONTACT_PHONE = "DISPLAY_AGENT_PROFILE_CONTACT_PHONE",
  DISPLAY_AGENT_PROFILE_CONTACT_SMS = "DISPLAY_AGENT_PROFILE_CONTACT_SMS",
  DISPLAY_AGENT_PROFILE_VIEW = "DISPLAY_AGENT_PROFILE_VIEW",
  LISTING_CLOSE_PRICE_GUESS_ADD = "LISTING_CLOSE_PRICE_GUESS_ADD",
  LISTING_CLOSE_PRICE_GUESS_REMOVE = "LISTING_CLOSE_PRICE_GUESS_REMOVE",
  LISTING_CLOSE_PRICE_GUESS_SHARE = "LISTING_CLOSE_PRICE_GUESS_SHARE",
  LISTING_DIRECT_COMMUNICATE = "LISTING_DIRECT_COMMUNICATE",
  LISTING_DISLIKE = "LISTING_DISLIKE",
  LISTING_DRAFT_CREATE = "LISTING_DRAFT_CREATE",
  LISTING_DRAFT_DELETE = "LISTING_DRAFT_DELETE",
  LISTING_DRAFT_EDIT = "LISTING_DRAFT_EDIT",
  LISTING_DRAFT_PUBLISH = "LISTING_DRAFT_PUBLISH",
  LISTING_DRAFT_REVIEW_SUBMIT = "LISTING_DRAFT_REVIEW_SUBMIT",
  LISTING_DRAFT_REVIEW_WITHDRAW = "LISTING_DRAFT_REVIEW_WITHDRAW",
  LISTING_DRAFT_VIEW = "LISTING_DRAFT_VIEW",
  LISTING_LIKE = "LISTING_LIKE",
  LISTING_LIST_CREATE = "LISTING_LIST_CREATE",
  LISTING_LIST_DELETE = "LISTING_LIST_DELETE",
  LISTING_LIST_ITEMS_ADD = "LISTING_LIST_ITEMS_ADD",
  LISTING_LIST_ITEMS_REMOVE = "LISTING_LIST_ITEMS_REMOVE",
  LISTING_LIST_RENAME = "LISTING_LIST_RENAME",
  LISTING_LIST_REORDER = "LISTING_LIST_REORDER",
  LISTING_LIST_SHARE = "LISTING_LIST_SHARE",
  LISTING_LIST_VIEW = "LISTING_LIST_VIEW",
  LISTING_SEARCH_SEARCH = "LISTING_SEARCH_SEARCH",
  LISTING_SEARCH_SHARE = "LISTING_SEARCH_SHARE",
  LISTING_SHARE = "LISTING_SHARE",
  LISTING_SUBSCRIBE = "LISTING_SUBSCRIBE",
  LISTING_UNDISLIKE = "LISTING_UNDISLIKE",
  LISTING_UNLIKE = "LISTING_UNLIKE",
  LISTING_UNSUBSCRIBE = "LISTING_UNSUBSCRIBE",
  LISTING_VIEW = "LISTING_VIEW",
  PRESENTING_AGENT_LISTING_NOTE_CREATE = "PRESENTING_AGENT_LISTING_NOTE_CREATE",
  PRESENTING_AGENT_LISTING_NOTE_DELETE = "PRESENTING_AGENT_LISTING_NOTE_DELETE",
  PRESENTING_AGENT_LISTING_NOTE_EDIT = "PRESENTING_AGENT_LISTING_NOTE_EDIT",
  PRESENTING_AGENT_LISTING_NOTE_VIEW = "PRESENTING_AGENT_LISTING_NOTE_VIEW",
  PRIVATE_LISTING_NOTE_CREATE = "PRIVATE_LISTING_NOTE_CREATE",
  PRIVATE_LISTING_NOTE_DELETE = "PRIVATE_LISTING_NOTE_DELETE",
  PRIVATE_LISTING_NOTE_EDIT = "PRIVATE_LISTING_NOTE_EDIT",
  PRIVATE_LISTING_NOTE_VIEW = "PRIVATE_LISTING_NOTE_VIEW",
  SAVED_LISTING_SEARCH_CREATE = "SAVED_LISTING_SEARCH_CREATE",
  SAVED_LISTING_SEARCH_DELETE = "SAVED_LISTING_SEARCH_DELETE",
  SAVED_LISTING_SEARCH_NEW_MATCHES_VIEW = "SAVED_LISTING_SEARCH_NEW_MATCHES_VIEW",
  SAVED_LISTING_SEARCH_RENAME = "SAVED_LISTING_SEARCH_RENAME",
  SAVED_LISTING_SEARCH_SHARE = "SAVED_LISTING_SEARCH_SHARE",
  SAVED_LISTING_SEARCH_SUBSCRIBE = "SAVED_LISTING_SEARCH_SUBSCRIBE",
  SAVED_LISTING_SEARCH_UNSUBSCRIBE = "SAVED_LISTING_SEARCH_UNSUBSCRIBE",
  SAVED_LISTING_SEARCH_VIEW = "SAVED_LISTING_SEARCH_VIEW",
  USER_SIGN_IN = "USER_SIGN_IN",
  USER_SIGN_OUT = "USER_SIGN_OUT",
}

export enum AgentUserPlanTier {
  PREMIUM = "PREMIUM",
  PROFESSIONAL = "PROFESSIONAL",
}

export enum CardinalDirection {
  E = "E",
  N = "N",
  NE = "NE",
  NW = "NW",
  S = "S",
  SE = "SE",
  SW = "SW",
  W = "W",
}

export enum CommercialPropertyType {
  AGRICULTURE = "AGRICULTURE",
  COMMERCIAL_RETAIL = "COMMERCIAL_RETAIL",
  FARM = "FARM",
  INDUSTRIAL = "INDUSTRIAL",
  INVESTMENT = "INVESTMENT",
  OFFICE = "OFFICE",
  OTHER = "OTHER",
  SALE_OF_BUSINESS = "SALE_OF_BUSINESS",
  STORE_WITH_APARTMENT_OR_OFFICE = "STORE_WITH_APARTMENT_OR_OFFICE",
  VACANT_LAND = "VACANT_LAND",
}

export enum CondominiumType {
  BARE_LAND = "BARE_LAND",
  CONVENTIONAL = "CONVENTIONAL",
  PARCEL_OF_TIED_LAND = "PARCEL_OF_TIED_LAND",
}

export enum GeolocatedListingOrderField {
  DAYS_AT_MARKET_AVAILABILITY = "DAYS_AT_MARKET_AVAILABILITY",
  DISTANCE_TO_CENTROID = "DISTANCE_TO_CENTROID",
  LATEST_PRICE = "LATEST_PRICE",
  LIST_PRICE = "LIST_PRICE",
}

export enum ListingFeedType {
  BROKER_LIST = "BROKER_LIST",
  MLS = "MLS",
}

export enum ListingOccupantType {
  OWNER = "OWNER",
  OWNER_AND_TENANT = "OWNER_AND_TENANT",
  TENANT = "TENANT",
  VACANT = "VACANT",
}

export enum ListingOwnershipType {
  CO_OPERATIVE = "CO_OPERATIVE",
  CO_OWNERSHIP = "CO_OWNERSHIP",
  FIRST_NATIONS_LEASE = "FIRST_NATIONS_LEASE",
  FRACTIONAL = "FRACTIONAL",
  FREEHOLD = "FREEHOLD",
  FREEHOLD_CONDOMINIUM = "FREEHOLD_CONDOMINIUM",
  FREEHOLD_STRATA = "FREEHOLD_STRATA",
  LEASEHOLD = "LEASEHOLD",
  LEASEHOLD_CONDOMINIUM = "LEASEHOLD_CONDOMINIUM",
  LEASEHOLD_STRATA = "LEASEHOLD_STRATA",
  LIFE_LEASE = "LIFE_LEASE",
  OTHER = "OTHER",
  TIME_SHARE = "TIME_SHARE",
}

export enum ListingReaction {
  DISLIKE = "DISLIKE",
  LIKE = "LIKE",
}

export enum ListingStatus {
  ACTIVE = "ACTIVE",
  ACTIVE_UNDER_CONTRACT = "ACTIVE_UNDER_CONTRACT",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  DELETE = "DELETE",
  EXPIRED = "EXPIRED",
  HOLD = "HOLD",
  INCOMPLETE = "INCOMPLETE",
  PENDING = "PENDING",
  UNKNOWN = "UNKNOWN",
  WITHDRAWN = "WITHDRAWN",
}

export enum ListingTransactionType {
  LEASE = "LEASE",
  SALE = "SALE",
  SALE_OR_LEASE = "SALE_OR_LEASE",
  SUBLEASE = "SUBLEASE",
}

export enum OrderDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum ResidentialBalconyType {
  ENCLOSED = "ENCLOSED",
  JULIET = "JULIET",
  NONE = "NONE",
  OPEN = "OPEN",
  TERRACE = "TERRACE",
}

export enum ResidentialBasementFeature {
  APARTMENT = "APARTMENT",
  OTHER = "OTHER",
  SEPARATE_ENTRANCE = "SEPARATE_ENTRANCE",
  SUITE = "SUITE",
  SUITE_ILLEGAL = "SUITE_ILLEGAL",
  SUITE_LEGAL = "SUITE_LEGAL",
  SUITE_LICENSED = "SUITE_LICENSED",
  SUITE_UNAUTHORIZED = "SUITE_UNAUTHORIZED",
  WALK_OUT = "WALK_OUT",
  WALK_UP = "WALK_UP",
}

export enum ResidentialBasementFinish {
  FINISHED = "FINISHED",
  PARTIAL = "PARTIAL",
  UNFINISHED = "UNFINISHED",
}

export enum ResidentialBasementSize {
  CRAWL_SPACE = "CRAWL_SPACE",
  FULL = "FULL",
  HALF = "HALF",
  NONE = "NONE",
  PARTIAL = "PARTIAL",
}

export enum ResidentialBuildingAmenity {
  BALCONY = "BALCONY",
  BBQ_PERMITTED = "BBQ_PERMITTED",
  BICYCLE_STORAGE = "BICYCLE_STORAGE",
  BUSINESS_CENTRE = "BUSINESS_CENTRE",
  CAR_WASH = "CAR_WASH",
  CLUBHOUSE = "CLUBHOUSE",
  CONCIERGE = "CONCIERGE",
  DAY_CARE = "DAY_CARE",
  ELEVATOR = "ELEVATOR",
  EXCERCISE_ROOM = "EXCERCISE_ROOM",
  GAMES_ROOM = "GAMES_ROOM",
  GARDEN = "GARDEN",
  GUEST_SUITES = "GUEST_SUITES",
  GYM = "GYM",
  HOT_TUB = "HOT_TUB",
  INDEPENDENT_LIVING = "INDEPENDENT_LIVING",
  MEDIA_ROOM = "MEDIA_ROOM",
  OTHER = "OTHER",
  PARTY_ROOM = "PARTY_ROOM",
  PLAYGROUND = "PLAYGROUND",
  POOL = "POOL",
  POOL_INDOOR = "POOL_INDOOR",
  POOL_LAP = "POOL_LAP",
  POOL_OUTDOOR = "POOL_OUTDOOR",
  RACQUET_COURT = "RACQUET_COURT",
  RECREATION_ROOM = "RECREATION_ROOM",
  RESTAURANT = "RESTAURANT",
  ROOFTOP_DECK = "ROOFTOP_DECK",
  SATELLITE_DISH = "SATELLITE_DISH",
  SAUNA = "SAUNA",
  SECURITY_GUARD = "SECURITY_GUARD",
  SECURITY_SYSTEM = "SECURITY_SYSTEM",
  SQUASH_COURT = "SQUASH_COURT",
  STORAGE = "STORAGE",
  TENNIS_COURT = "TENNIS_COURT",
  VISITOR_PARKING = "VISITOR_PARKING",
  WEEKLY_HOUSEKEEPING = "WEEKLY_HOUSEKEEPING",
  WHEELCHAIR_ACCESS = "WHEELCHAIR_ACCESS",
  WORKSHOP = "WORKSHOP",
}

export enum ResidentialConnectedUtilityAvailability {
  AVAILABLE = "AVAILABLE",
  CONNECTED = "CONNECTED",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum ResidentialConnectedUtilityType {
  CABLE = "CABLE",
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
  INTERNET = "INTERNET",
  TELEPHONE = "TELEPHONE",
  WATER = "WATER",
}

export enum ResidentialCoolingType {
  AIR_CONDITIONING = "AIR_CONDITIONING",
  CENTRAL_AIR = "CENTRAL_AIR",
  CENTRAL_AIR_ROUGHED_IN = "CENTRAL_AIR_ROUGHED_IN",
  HEAT_PUMP = "HEAT_PUMP",
  NONE = "NONE",
  OTHER = "OTHER",
  PARTIAL_AIR_CONDITIONING = "PARTIAL_AIR_CONDITIONING",
  WALL_UNIT = "WALL_UNIT",
  WINDOW_UNIT = "WINDOW_UNIT",
}

export enum ResidentialDrivewayType {
  AVAILABLE = "AVAILABLE",
  CIRCULAR = "CIRCULAR",
  FACILITIES = "FACILITIES",
  FRONT_YARD = "FRONT_YARD",
  LANE = "LANE",
  MUTUAL = "MUTUAL",
  NONE = "NONE",
  OTHER = "OTHER",
  PRIVATE = "PRIVATE",
  PRIVATE_DOUBLE = "PRIVATE_DOUBLE",
  PRIVATE_TRIPLE_PLUS = "PRIVATE_TRIPLE_PLUS",
  RIGHT_OF_WAY = "RIGHT_OF_WAY",
  RV_OR_TRUCK = "RV_OR_TRUCK",
  SURFACE = "SURFACE",
  TRUCK_YARD = "TRUCK_YARD",
  UNDERGROUND = "UNDERGROUND",
}

export enum ResidentialExposure {
  E = "E",
  ESW = "ESW",
  EW = "EW",
  N = "N",
  NE = "NE",
  NEW = "NEW",
  NS = "NS",
  NSE = "NSE",
  NSEW = "NSEW",
  NSW = "NSW",
  NW = "NW",
  S = "S",
  SE = "SE",
  SEW = "SEW",
  SW = "SW",
  W = "W",
}

export enum ResidentialExterior {
  ALUMINUM_SIDING = "ALUMINUM_SIDING",
  ASPHALT_SHINGLE = "ASPHALT_SHINGLE",
  BOARD_BATTEN = "BOARD_BATTEN",
  BRICK = "BRICK",
  BRICK_FRONT = "BRICK_FRONT",
  CEDAR = "CEDAR",
  CEDAR_SIDING = "CEDAR_SIDING",
  COMPOSITE_SIDING = "COMPOSITE_SIDING",
  CONCRETE = "CONCRETE",
  FIBER_CEMENT_SIDING = "FIBER_CEMENT_SIDING",
  GLASS = "GLASS",
  HARDBOARD = "HARDBOARD",
  INSULBRICK = "INSULBRICK",
  LOG = "LOG",
  LOG_SIDING = "LOG_SIDING",
  METAL = "METAL",
  OTHER = "OTHER",
  SHINGLE = "SHINGLE",
  SIDING = "SIDING",
  STONE = "STONE",
  STUCCO_PLASTER = "STUCCO_PLASTER",
  VINYL = "VINYL",
  WOOD = "WOOD",
  WOOD_SHINGLE = "WOOD_SHINGLE",
  WOOD_SIDING = "WOOD_SIDING",
}

export enum ResidentialFurnished {
  NO = "NO",
  PARTIAL = "PARTIAL",
  YES = "YES",
}

export enum ResidentialGarageType {
  ATTACHED = "ATTACHED",
  BUILT_IN = "BUILT_IN",
  CARPORT = "CARPORT",
  COVERED = "COVERED",
  DETACHED = "DETACHED",
  GARAGE = "GARAGE",
  INSIDE_ENTRY = "INSIDE_ENTRY",
  NONE = "NONE",
  OTHER = "OTHER",
  PARKADE = "PARKADE",
  SURFACE = "SURFACE",
  TANDEM = "TANDEM",
  UNDERGROUND = "UNDERGROUND",
}

export enum ResidentialHeatingHeatSourceType {
  ELECTRIC = "ELECTRIC",
  GAS = "GAS",
  GAS_WELL = "GAS_WELL",
  GEOTHERMAL = "GEOTHERMAL",
  GROUND_SOURCE = "GROUND_SOURCE",
  HOT_WATER = "HOT_WATER",
  NONE = "NONE",
  OIL = "OIL",
  OTHER = "OTHER",
  PELLET = "PELLET",
  PROPANE = "PROPANE",
  SOLAR = "SOLAR",
  WIND = "WIND",
  WOOD = "WOOD",
}

export enum ResidentialHeatingType {
  BASEBOARD = "BASEBOARD",
  FAN_COIL = "FAN_COIL",
  FIREPLACE = "FIREPLACE",
  FORCED_AIR = "FORCED_AIR",
  FURNACE = "FURNACE",
  GRAVITY = "GRAVITY",
  HEAT_PUMP = "HEAT_PUMP",
  HOT_WATER = "HOT_WATER",
  IN_FLOOR = "IN_FLOOR",
  NONE = "NONE",
  OTHER = "OTHER",
  RADIANT = "RADIANT",
  RADIATORS = "RADIATORS",
  SOLAR = "SOLAR",
  SPACE_HEATER = "SPACE_HEATER",
  STEAM_RADIATORS = "STEAM_RADIATORS",
  STOVE = "STOVE",
  THERMAL_STORAGE = "THERMAL_STORAGE",
  WATER = "WATER",
  WATER_RADIATORS = "WATER_RADIATORS",
}

export enum ResidentialIncludedUtilityType {
  BUILDING_INSURANCE = "BUILDING_INSURANCE",
  CABLE = "CABLE",
  CARETAKER = "CARETAKER",
  CENTRAL_AIR = "CENTRAL_AIR",
  COMMON_AREA_JANITORIAL_SERVICES = "COMMON_AREA_JANITORIAL_SERVICES",
  COMMON_AREA_MAINTENANCE = "COMMON_AREA_MAINTENANCE",
  COMMON_ELEMENTS = "COMMON_ELEMENTS",
  CONDO_TAXES = "CONDO_TAXES",
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
  HEAT = "HEAT",
  INSURANCE = "INSURANCE",
  INTERNET = "INTERNET",
  LANDSCAPING = "LANDSCAPING",
  MAINTENANCE = "MAINTENANCE",
  MAINTENANCE_COMMON_AREA = "MAINTENANCE_COMMON_AREA",
  MAINTENANCE_EXTERIOR = "MAINTENANCE_EXTERIOR",
  MAINTENANCE_GROUNDS = "MAINTENANCE_GROUNDS",
  OTHER = "OTHER",
  PARKING = "PARKING",
  PEST_CONTROL = "PEST_CONTROL",
  PROFESSIONAL_MANAGEMENT = "PROFESSIONAL_MANAGEMENT",
  PROPERTY_MANAGEMENT = "PROPERTY_MANAGEMENT",
  PROPERTY_TAXES = "PROPERTY_TAXES",
  RECREATIONAL_FACILITIES = "RECREATIONAL_FACILITIES",
  RESERVE_FUND_CONTRIBUTIONS = "RESERVE_FUND_CONTRIBUTIONS",
  SECURITY = "SECURITY",
  SEWER = "SEWER",
  SNOW_REMOVAL = "SNOW_REMOVAL",
  STORAGE = "STORAGE",
  TELEPHONE = "TELEPHONE",
  WASTE = "WASTE",
  WATER = "WATER",
}

export enum ResidentialLaundryAccess {
  COIN_OPERATED = "COIN_OPERATED",
  ENSUITE = "ENSUITE",
  IN_AREA = "IN_AREA",
  IN_SUITE = "IN_SUITE",
  NONE = "NONE",
  OTHER = "OTHER",
  SET_USAGE = "SET_USAGE",
  SHARED = "SHARED",
}

export enum ResidentialLockerType {
  COMMON = "COMMON",
  ENSUITE = "ENSUITE",
  ENSUITE_COMMON = "ENSUITE_COMMON",
  ENSUITE_EXCLUSIVE = "ENSUITE_EXCLUSIVE",
  ENSUITE_OWNED = "ENSUITE_OWNED",
  EXCLUSIVE = "EXCLUSIVE",
  IN_SUITE = "IN_SUITE",
  IN_SUITE_COMMON = "IN_SUITE_COMMON",
  IN_SUITE_EXCLUSIVE = "IN_SUITE_EXCLUSIVE",
  IN_SUITE_OWNED = "IN_SUITE_OWNED",
  NONE = "NONE",
  OWNED = "OWNED",
}

export enum ResidentialPetsPermitted {
  CATS_OK = "CATS_OK",
  DOGS_OK = "DOGS_OK",
  NO = "NO",
  RESTRICTIONS = "RESTRICTIONS",
  YES = "YES",
}

export enum ResidentialPoolType {
  ABOVE_GROUND = "ABOVE_GROUND",
  COMMUNITY = "COMMUNITY",
  INDOOR = "INDOOR",
  IN_GROUND = "IN_GROUND",
  NONE = "NONE",
  ON_GROUND = "ON_GROUND",
  OTHER = "OTHER",
  OUTDOOR = "OUTDOOR",
}

export enum ResidentialPropertyStyleArchitectural {
  BUNGALOW = "BUNGALOW",
  LOFT = "LOFT",
  STUDIO = "STUDIO",
}

export enum ResidentialPropertyStyleAttachment {
  ATTACHED = "ATTACHED",
  DETACHED = "DETACHED",
  LINK = "LINK",
  SEMI_DETACHED = "SEMI_DETACHED",
  STACKED = "STACKED",
}

export enum ResidentialPropertyStyleLevel {
  BACKSPLIT = "BACKSPLIT",
  BI_LEVEL = "BI_LEVEL",
  FIVE_STOREY = "FIVE_STOREY",
  FOUR_STOREY = "FOUR_STOREY",
  MULTI_LEVEL = "MULTI_LEVEL",
  ONE_AND_HALF_STOREY = "ONE_AND_HALF_STOREY",
  ONE_STOREY = "ONE_STOREY",
  RAISED = "RAISED",
  SIDESPLIT = "SIDESPLIT",
  SPLIT_LEVEL = "SPLIT_LEVEL",
  THREE_STOREY = "THREE_STOREY",
  TWO_AND_HALF_STOREY = "TWO_AND_HALF_STOREY",
  TWO_STOREY = "TWO_STOREY",
}

export enum ResidentialPropertyType {
  APARTMENT = "APARTMENT",
  COTTAGE = "COTTAGE",
  DUPLEX = "DUPLEX",
  FARM = "FARM",
  FOURPLEX = "FOURPLEX",
  HOUSE = "HOUSE",
  LOCKER = "LOCKER",
  MIXED = "MIXED",
  MOBILE_TRAILER = "MOBILE_TRAILER",
  MODULAR = "MODULAR",
  MULTIPLEX = "MULTIPLEX",
  OTHER = "OTHER",
  PARKING_SPACE = "PARKING_SPACE",
  RECREATIONAL = "RECREATIONAL",
  ROOM = "ROOM",
  ROW_TOWNHOUSE = "ROW_TOWNHOUSE",
  TRIPLEX = "TRIPLEX",
  VACANT_LAND = "VACANT_LAND",
}

export enum ResidentialWaterSewerType {
  COMMUNITY = "COMMUNITY",
  HOLDING_TANK = "HOLDING_TANK",
  LAGOON = "LAGOON",
  NONE = "NONE",
  OTHER = "OTHER",
  PRIVATE = "PRIVATE",
  SEPTIC = "SEPTIC",
  SEPTIC_APPROVED = "SEPTIC_APPROVED",
  SEWER = "SEWER",
  UNKNOWN = "UNKNOWN",
}

export enum ResidentialWaterSourceType {
  CISTERN = "CISTERN",
  COMMUNITY = "COMMUNITY",
  COOPERATIVE = "COOPERATIVE",
  DUGOUT = "DUGOUT",
  LAKE = "LAKE",
  LAKE_RIVER = "LAKE_RIVER",
  MUNICIPAL = "MUNICIPAL",
  NONE = "NONE",
  OTHER = "OTHER",
  PRIVATE = "PRIVATE",
  SPRING = "SPRING",
  UNKNOWN = "UNKNOWN",
  WELL = "WELL",
  WELL_ARTESIAN = "WELL_ARTESIAN",
  WELL_BORED = "WELL_BORED",
  WELL_COMMUNITY = "WELL_COMMUNITY",
  WELL_DRILLED = "WELL_DRILLED",
  WELL_DUG = "WELL_DUG",
  WELL_SANDPOINT = "WELL_SANDPOINT",
  WELL_SHALLOW = "WELL_SHALLOW",
  WELL_SHARED = "WELL_SHARED",
  WELL_SHORE = "WELL_SHORE",
  WELL_SURFACE = "WELL_SURFACE",
}

export enum ResidentialWaterfrontAccess {
  DIRECT = "DIRECT",
  INDIRECT = "INDIRECT",
  NONE = "NONE",
  WATERFRONT = "WATERFRONT",
}

export interface ActorEventGenericInput {
  readonly type: ActorEventType;
}

export interface ActorEventNodeResourceInput {
  readonly type: ActorEventType;
  readonly resourceId: string;
}

export interface AddListingListItemInput {
  readonly listingList: string;
  readonly listing: string;
}

export interface AgentSignUpInput {
  readonly name: string;
  readonly email?: GQL_EmailAddress | null;
  readonly phoneNumber?: string | null;
  readonly officeName?: string | null;
  readonly boards?: ReadonlyArray<string> | null;
}

export interface AgentUserAccountSettingsInput {
  readonly accountEmail?: GQL_EmailAddress | null;
  readonly pictureURL?: GQL_URL | null;
  readonly name: string;
  readonly about?: string | null;
  readonly notifiableViaEmail: boolean;
  readonly notifiableViaSms: boolean;
  readonly contactableViaPhone: boolean;
  readonly contactableViaSms: boolean;
  readonly contactableViaEmail: boolean;
  readonly legalName?: string | null;
  readonly position?: string | null;
  readonly contactPhone?: GQL_PhoneNumber | null;
  readonly contactPhoneExtension?: string | null;
  readonly contactEmail?: GQL_EmailAddress | null;
  readonly contactWebsite?: GQL_URL | null;
  readonly suppressListingsAsListingAgent?: boolean | null;
}

export interface AssignBrowsingSessionInput {
  readonly target?: GQL_URL | null;
  readonly fingerprint?: string | null;
}

export interface BecomeClientInput {
  readonly desiredAgentId?: string | null;
  readonly fullName: string;
  readonly correspondenceEmail?: GQL_EmailAddress | null;
  readonly about?: string | null;
  readonly subscribeToUpdates: boolean;
}

export interface BoundingBoxInput {
  readonly sw: PointInput;
  readonly ne: PointInput;
}

export interface ClientUserAccountSettingsInput {
  readonly accountEmail?: GQL_EmailAddress | null;
  readonly pictureURL?: GQL_URL | null;
  readonly name: string;
  readonly about?: string | null;
  readonly notifiableViaEmail: boolean;
  readonly notifiableViaSms: boolean;
  readonly contactableViaPhone: boolean;
  readonly contactableViaSms: boolean;
  readonly contactableViaEmail: boolean;
  readonly collaborateWithAgent: boolean;
}

export interface ConnectToProvidingAgentInput {
  readonly desiredAgentId: string;
}

export interface CreateDynamicShortLinkInput {
  readonly title?: string | null;
  readonly target: GQL_URL;
}

export interface CreateListingListInput {
  readonly title: string;
}

export interface CreateSavedListingSearchInput {
  readonly title: string;
  readonly userId?: string | null;
  readonly polyline: string;
  readonly feedTypes?: ReadonlyArray<ListingFeedType> | null;
  readonly statuses?: ReadonlyArray<ListingStatus> | null;
  readonly transactionTypes?: ReadonlyArray<ListingTransactionType> | null;
  readonly ownershipTypes?: ReadonlyArray<ListingOwnershipType> | null;
  readonly propertyTypes?: ReadonlyArray<ResidentialPropertyType> | null;
  readonly hasOpenHouse?: boolean | null;
  readonly priceRange?: IntRangeInput | null;
  readonly maintenanceFeeRange?: IntRangeInput | null;
  readonly bedroomsRange?: IntRangeInput | null;
  readonly bedroomsPlusRange?: IntRangeInput | null;
  readonly bathroomsRange?: IntRangeInput | null;
  readonly kitchensRange?: IntRangeInput | null;
  readonly parkingSpacesRange?: IntRangeInput | null;
  readonly garageSpacesRange?: IntRangeInput | null;
  readonly lotSizeWidthRange?: FloatRangeInput | null;
  readonly lotSizeDepthRange?: FloatRangeInput | null;
  readonly lotSizeAreaRange?: FloatRangeInput | null;
  readonly interiorAreaRange?: FloatRangeInput | null;
  readonly storeysRange?: FloatRangeInput | null;
  readonly styleAttachments?: ReadonlyArray<(ResidentialPropertyStyleAttachment | null)> | null;
  readonly hasWaterfrontAccess?: boolean | null;
  readonly daysAtMarketAvailabilityRange?: IntRangeInput | null;
  readonly viewerReactions?: ReadonlyArray<(ListingReaction | null)> | null;
}

export interface DeleteDynamicShortLinkInput {
  readonly id: string;
}

export interface DeleteListingListInput {
  readonly listingList: string;
}

export interface DeleteSavedListingSearchInput {
  readonly savedListingSearch: string;
}

export interface FloatRangeInput {
  readonly begin?: number | null;
  readonly end?: number | null;
}

export interface GeolocatedListingOrder {
  readonly direction: OrderDirection;
  readonly field: GeolocatedListingOrderField;
}

export interface IntRangeInput {
  readonly begin?: number | null;
  readonly end?: number | null;
}

export interface KeyValueInputItem {
  readonly key: string;
  readonly value: string;
}

export interface ListingListItemMovementsInput {
  readonly item: string;
  readonly position: number;
}

export interface ListingListItemsAddActorEventInput {
  readonly listingList: string;
  readonly listings: ReadonlyArray<string>;
}

export interface ListingListItemsRemoveActorEventInput {
  readonly listingList: string;
  readonly items: ReadonlyArray<string>;
}

export interface ListingSearchSearchActorEventBoundingBoxInput {
  readonly bbox: BoundingBoxInput;
}

export interface ListingSearchSearchActorEventDetailsInput {
  readonly nearest?: ListingSearchSearchActorEventNearestInput | null;
  readonly boundingBox?: ListingSearchSearchActorEventBoundingBoxInput | null;
  readonly polylinePolygon?: ListingSearchSearchActorEventPolylinePolygonInput | null;
  readonly property?: ListingSearchSearchActorEventPropertyInput | null;
  readonly mlsNumber?: ListingSearchSearchActorEventMlsNumberInput | null;
  readonly savedListingSearch?: ListingSearchSearchActorEventSavedListingSearchInput | null;
}

export interface ListingSearchSearchActorEventInput {
  readonly searchTerm?: string | null;
  readonly inputSearchTerm?: string | null;
  readonly currentLocation?: PointInput | null;
  readonly search: ListingSearchSearchActorEventDetailsInput;
}

export interface ListingSearchSearchActorEventMlsNumberInput {
  readonly mlsNumber: string;
}

export interface ListingSearchSearchActorEventNearestInput {
  readonly point: PointInput;
}

export interface ListingSearchSearchActorEventPolylinePolygonInput {
  readonly polyline: string;
}

export interface ListingSearchSearchActorEventPropertyInput {
  readonly propertyId: string;
}

export interface ListingSearchSearchActorEventSavedListingSearchInput {
  readonly savedListingSearchId: string;
}

export interface MarkNotificationAsSeenInput {
  readonly notification: string;
}

export interface MarkSavedListingSearchExecutionInput {
  readonly savedListingSearch: string;
}

export interface PointInput {
  readonly lat: number;
  readonly lon: number;
}

export interface RegisterUserDeviceInput {
  readonly deviceToken: string;
}

export interface RemoveListingListItemInput {
  readonly listingList: string;
  readonly item: string;
}

export interface ReorderListingListItemsInput {
  readonly listingList: string;
  readonly movements: ReadonlyArray<ListingListItemMovementsInput>;
}

export interface SetAgentUserClientRelationshipNoteInput {
  readonly body: string;
  readonly relationship: string;
}

export interface SetListingNoteInput {
  readonly body: string;
  readonly listing: string;
}

export interface SetListingReactionInput {
  readonly listing: string;
  readonly reaction?: ListingReaction | null;
}

export interface SetListingSubscriptionInput {
  readonly listing: string;
  readonly subscribe: boolean;
}

export interface SetSavedListingSearchSubscriptionInput {
  readonly savedListingSearch: string;
  readonly subscribe: boolean;
}

export interface UpdateDynamicShortLinkInput {
  readonly id: string;
  readonly target?: GQL_URL | null;
  readonly title?: string | null;
}

export interface UpdateListingListInput {
  readonly listingList: string;
  readonly title?: string | null;
}

export interface UpdateSavedListingSearchInput {
  readonly savedListingSearch: string;
  readonly title: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
